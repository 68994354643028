var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SectionHeader',{attrs:{"title":"ABOUT US"}}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-around"}},[(!_vm.isMobile)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-lazy',{attrs:{"value":true}},[_c('v-img',{attrs:{"contain":"","src":require('../assets/storefront-1.jpg')}})],1),_c('v-dialog',{attrs:{"width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-5 t4 no-border",staticStyle:{"white-space":"normal"},attrs:{"block":"","x-large":"","dark":""}},on),[_c('span',{staticClass:"medium t1",staticStyle:{"white-space":"normal"}},[_vm._v("Read More...")])])]}}],null,false,2138065035),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"grey darken-3"},[_c('v-card-title',{staticClass:"align-baseline"},[_c('p',{staticClass:"flex mt-2 t2 x4"},[_vm._v("The My 4 Sons Story")]),_c('v-tooltip',{attrs:{"left":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":hover ? 'red' : ''},on:{"click":function($event){_vm.dialog = false}}},on),[_c('v-icon',{staticStyle:{"justify-self":"right"}},[_vm._v("mdi-close")])],1)]}}],null,true)})]}}],null,false,648187400)},[_c('span',[_vm._v("Close")])])],1),_c('v-divider',{staticClass:"mx-5 my-n2",attrs:{"dark":""}}),_c('v-card-text',{staticClass:"t4 mt-5 white--text small",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.text.dialog)+" "),_c('p',{staticClass:"mt-5 mr-10 text-right"},[_vm._v("- Matt, Owner")])])],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.isMobile ? 12 : 7}},[_c('p',{staticClass:"t4 medium text-left",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.text.top))]),_c('v-divider',{staticClass:"my-3",attrs:{"dark":""}}),_c('p',{staticClass:"t4 medium text-left",staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.text.bottom))])],1),(_vm.isMobile)?_c('v-dialog',{attrs:{"width":"600px","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-3 text-wrap no-border",attrs:{"block":"","dark":"","x-large":""}},on),[_c('span',{staticClass:"large t1",staticStyle:{"white-space":"normal"}},[_vm._v("Read More...")])])]}}],null,false,1424509841),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-divider',{staticClass:"mx-5",attrs:{"dark":""}}),_c('v-card-text',{staticClass:"t4 mt-5 white--text small",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.text.dialog)+" "),_c('p',{staticClass:"mt-5 mr-10 text-right"},[_vm._v("- Matt, Owner")])]),_c('v-card-actions',[_c('v-btn',{staticClass:"no-border t1",attrs:{"x-large":"","color":"red darken-1","block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }