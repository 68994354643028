<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>
import Home from "./components/Home"

export default {
  name      : "App",
  components: {
    Home,
  },
}
</script>
