import Vue from "vue"
import "./plugins/axios"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import "./styles.css"

Vue.config.productionTip = false;

new Vue({
            vuetify,
            render: h => h(App)
        }).$mount("#app");
